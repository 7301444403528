(function ($, DataTable) {
    "use strict";

    var classDefault = ' btn btn-outline';

    var buildUrl = function(dt, params) {
        return (dt.ajax.url() || '') + '?' + $.param(params)
    };

    var doExport = function(dt, action) {
        var params = dt.ajax.params();
        params.action = action;

        window.location = buildUrl(dt, params);

        // if (dt.page.info() && dt.page.info().recordsDisplay < 4500) {
        //     window.location = buildUrl(dt, params);
        // } else {
        //     params.queue = 1;
        //
        //     $.get(buildUrl(dt, params), function (r) {
        //         swal('Exporting', 'Your data will be exported and sent to your e-mail address. You will also be notified via the app. Large exports can take 15-30 minutes.', 'success');
        //     }).fail(function () {
        //         swal('Export Failed', 'Something went wrong with the export. We\'ve been notified and will fix the issue shortly', 'error');
        //     });
        // }
    };

    var blockUiExport = function () {
        App.blockUI({
            message: 'Exporting...'
        })
    };

    DataTable.ext.buttons.select = {
        className: 'buttons-select green' + classDefault,

        text: function (dt) {
            return '<i class="fa fa-check-square-o"></i> ' + dt.i18n('buttons.select', 'Select All');
        }
    };

    DataTable.ext.buttons.colvis = {
        extend: 'collection',
        text: function ( dt ) {
            return 'Columns'
        },
        className: 'buttons-colvis red' + classDefault,
        buttons: [ {
            extend: 'columnsToggle',
        } ]
    };

    DataTable.ext.buttons.export = {
        className: 'buttons-export blue' + classDefault,
        extend: 'collection',

        text: function (dt) {
            return '<i class="fa fa-download"></i> ' + dt.i18n('buttons.export', 'Export') + '&nbsp;<span class="caret"/>';
        },

        buttons: ['csv', 'excel', 'pdf']
    };

    DataTable.ext.buttons.excel = {
        className: 'buttons-excel',

        text: function (dt) {
            return '<i class="fa fa-file-excel-o"></i> ' + dt.i18n('buttons.excel', 'Excel');
        },

        action: function (e, dt, button, config) {
            // blockUiExport();
            doExport(dt, 'excel');
            // window.location = url;
        }
    };

    DataTable.ext.buttons.csv = {
        className: 'buttons-csv',

        text: function (dt) {
            return '<i class="fa fa-file-excel-o"></i> ' + dt.i18n('buttons.csv', 'CSV');
        },

        action: function (e, dt, button, config) {
            // blockUiExport();
            doExport(dt, 'csv');
            // window.location = url;
        }
    };

    DataTable.ext.buttons.pdf = {
        className: 'buttons-pdf',

        text: function (dt) {
            return '<i class="fa fa-file-pdf-o"></i> ' + dt.i18n('buttons.pdf', 'PDF');
        },

        action: function (e, dt, button, config) {
            // blockUiExport();
            doExport(dt, 'pdf');
            //window.location = url;
        }
    };

    DataTable.ext.buttons.print = {
        className: 'buttons-print dark' + classDefault,

        text: function (dt) {
            return  '<i class="fa fa-print"></i> ' + dt.i18n('buttons.print', 'Print');
        },

        action: function (e, dt, button, config) {
            doExport(dt, 'print');
        }
    };

    DataTable.ext.buttons.reset = {
        className: 'buttons-reset red ' + classDefault,

        text: function (dt) {
            return '<i class="fa fa-undo"></i> ' + dt.i18n('buttons.reset', 'Reset');
        },

        action: function (e, dt, button, config) {
            dt.search('').draw();
        }
    };

    DataTable.ext.buttons.reload = {
        className: 'buttons-reload yellow ' + classDefault,

        text: function (dt) {
            return '<i class="fa fa-refresh"></i> ' + dt.i18n('buttons.reload', 'Reload');
        },

        action: function (e, dt, button, config) {
            dt.draw(false);
        }
    };

    DataTable.ext.buttons.mapCreate = {
        className: 'buttons-create blue ' + classDefault,
        text: function (dt) {
            return '<i class="fa fa-plus"></i> Create'
        },
        action: function (e, dt, button, config) {

        }
    };

    DataTable.ext.buttons.import = {
        className: 'buttons-import dt-import purple ' + classDefault,
        text: function (dt) {
            return '<i class="fa fa-upload"></i> Import'
        },
        action: function (e, dt, button, config) {
            // $("#importFile").trigger('click');
        }
    };

    DataTable.ext.buttons.cacheNumViolations = {
        className: 'buttons-cache-num-violations green ' + classDefault,
        text: function (dt) {
            return '<i class="fa fa-hourglass-start"></i> Cache'
        },
        action: function (e, dt, button, config) {

        }
    };

    DataTable.ext.buttons.deleteIds = {
        className: 'buttons-delete-products red ' + classDefault,
        text: function (dt) {
            return '<i class="fa fa-trash-o"></i> Delete IDs'
        },
        action: function (e, dt, button, config) {

        }
    };
})(jQuery, jQuery.fn.dataTable);
